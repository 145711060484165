<template>
    <div>
        <AdminTitleBar
            :title="loc('Survey Responses')"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />        
        <v-row v-if="tablePrefs">
            <div v-for="(item, idx) in headers" v-bind:key="idx">
                <v-checkbox style="min-width: 120px;"
                    :name="idx"
                    v-model="item.show"
                    :label="loc(item.text)"
                    @click.stop="saveheaders"
                ></v-checkbox>
            </div>
        </v-row>   
        <div v-if="recordList">
            <v-row class="searchrow">
                <v-select :items="languages" :label=" loc('Language') " v-model="filterlanguage" class="ml-3" ></v-select>
                <v-select :items="surveySelect" :label=" loc('Survey') " v-model="filterSurvey" class="ml-3" ></v-select>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    :label="loc('Search')"
                    single-line
                    hide-details
                ></v-text-field>
            </v-row>

            <v-data-table
                :items-per-page="itemsPerPage"
                :headers="tableHeaders"
                :footer-props="{ itemsPerPageOptions:[10,20,30,40,50,100,200] }"
                :items="recordList"
                :search="search"
                class="courseList elevation-1  my-8 pb2"
            >
                <template v-slot:[`header.actions`]="{header}">
                    {{header.text}}
                    <span @click="tablePrefs=!tablePrefs" class="dtableactionicon"><v-icon small >edit</v-icon> {{ loc("Columns") }}</span>
                </template>
                <template v-slot:[`item.title`]="{ item }" class="linkcolumn titlecolumn">
                    {{ item.title }}
                </template>   
                <template v-slot:[`item.answers`]="{ item }" class="linkcolumn titlecolumn">
                    <div v-for="(a, k) in item.answers" v-bind:key="item._id+k">{{ a }} ({{ k }})</div>
                </template>                    
            </v-data-table>            
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AdminTitleBar from "./Admin_TitleBar";
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';

export default {
    name: "AdminSurveyResponses",
    props:  {
        viewmain: {type: [String, Boolean]}, 
        // viewlanguage: { type: [String, Boolean]},
        surveyId: { default: false }
    },
    components: { AdminTitleBar },
    data() {
        return {
            itemsPerPage: 50,
            tablePrefs: false,
            manageRecord: false,
            search: "",
            filterSurvey: this.surveyId,
            filterlanguage: false,
            headers: {
                _id: {
                    text: "ID",
                    value: "_id",
                },
                surveyId: {
                    text: "Survey ID",
                    value: "surveyId",
                },
                title: {
                    text: "Survey Title",
                    value: "surveyTitle",
                    sortable: true,
                    align: "start",
                    show: true                    
                },
                user: {
                    text: "User",
                    value: "user",

                },
                lang: {
                    text: "Language",
                    value: "lang",
                    sortable: true,
                    align: "start",
                    show: true                    
                },
                context: {
                    text: "Context",
                    value: "context",
                    show: true,
                },
                contextid: {
                    text: "Context Id",
                    value: "contextid",
                    show: true

                },
                time: {
                    text: "Time",
                    value: "time",
                    show: true
                },
                answers: {
                    text: "Answers",
                    value: "answers"
                },
            },
            titleBarActions: [
                { text: "Export Surveys", key: "exportSurveys", href: "/app_reports/surveys/surveys.csv"},
                { text: "Export Responses", key: "exportResponses", href: "/app_reports/surveyanswers/surveyresponses.csv"},

            ],            
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'adminstore', 'surveys', 'authError']),
        tableHeaders: function() {
            var temp = Object.assign({}, this.headers);
            let columns = []
            for(let k in temp){
                if(temp[k].show){
                    temp[k].sortable = true;
                    temp[k].align = temp[k].align || "start";
                    columns.push(temp[k]);
                }
            }

            columns.push({ text: "", sortable: false, value: "actions" });
            return columns;   
        },
        recordList: function() {
            if(!this.surveys.responses) return [];

            let list = Object.values(this.surveys.responses);
            if(this.filterlanguage){
                _filter(list, {lang: this.filterlanguage});
            }
            if(this.filterSurvey){
                _filter(list, {surveyId: this.filterSurvey});
            }

            list = _sortBy(list, ['time']);
            return list;
        },
        courseSelect: function(){ return [] },
        languages: function(){
            let list = (this.adminstore && this.adminstore.siteconfig && this.adminstore.siteconfig.languages) || {en:"English"};
            let out = [];
            for(let k in list){
                out.push({ value: k, text: list[k] });
            }
            out.unshift({text: 'Show All', value: false})
            return out;
        },    
        surveySelect: function(){
            if(!this.surveys || !this.surveys.list ) return [];
            let list = [];
            for(let k in this.surveys.list){
                if(this.surveys.list[k].status === 'Active'){
                    list.push({value: k, text:this.surveys.list[k].title })
                }
            }
            list = _sortBy(list, ['text']);
            return list;
        }             
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },   
        getList: function() {
            this.$store.dispatch("surveys/getResponses")
                .then((result) => {
                    console.log("Got it", result);
                })
        },
        handleAction: function(){

        },
        saveheaders: function(){
            localStorage.AdminSurveyResponsesList_headers = JSON.stringify(this.headers);
        },

        init: function(){
            this.getList();
        }


    },
    created() {
        console.log("Creating Admin.Surveys", this.$route)
        this.isLoading = true;
        this.init()
    },
    watch: {
        '$route.params.id': {
            handler: function(s){
                console.log("route watcher triggered", s, this.$route);
                this.init()
            },
            deep: true,
            immediate: false
        }
    }
}
</script>
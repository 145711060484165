<template>
    <div>
        <AdminTitleBar
            :title="loc('Manage Lessons') + ' - ' + course.title "
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />  
        <h4 v-text="'Editing ' + record._id" ></h4>
        <v-card id="editlessonproperties" class="pa-2">
            <v-card-text class='lessonform'>
                <v-row justify="space-between">
                    <v-text-field
                        name="title"
                        v-model="record.title"
                        :label="loc('Title')"
                    ></v-text-field>
                </v-row>  
                <!-- <v-row justify="space-between">
                    <v-text-field
                        name="course"
                        v-model="record.course"
                        :label="loc('Course')"
                    ></v-text-field>
                </v-row>                        -->
                <v-row justify="space-between">
                    <v-select
                        name="lang"
                        v-model="record.lang"
                        :label="loc('Language')"
                        :items="languages"
                    ></v-select>
                </v-row>   
                <v-row justify="space-between">
                    <v-text-field
                        name="order"
                        v-model="record.order"
                        :label="loc('Order')"
                    ></v-text-field>
                </v-row>       
                <v-row justify="space-between">
                    <v-select
                        name="type"
                        v-model="record.type"
                        :label="loc('Type')"
                        :items="['Lesson','Lab']"
                    ></v-select>
                </v-row>                           
                <!-- <v-row justify="space-between">
                    <v-text-field
                        name="video"
                        v-model="record.video"
                        :label="loc('Intro Video URL')"
                    ></v-text-field>
                </v-row>                   -->
                <v-row justify="space-between" class="pt-4 pb-4">
                    <div style="width: 100%; padding-bottom: 12px;">
                        <label for="lesson-description" class="v-label theme--light" style="left: 0px; right: auto; position: relative;">{{ loc("Lesson Description") }}</label>
                    </div>
                    <SharedEditor
                        name="description"
                        :record="record.description"
                        :handleChange="handleDescriptionChange"
                        id="lesson-description"
                    ></SharedEditor>
                </v-row>  
                <v-divider class="mx-4"></v-divider>                         
                <v-row class='actionrow mt-2'>
                    <v-btn small color="primary" dark @click="handleSave()" class="mr-1">{{ loc("Save Changes") }}</v-btn>
                    <v-btn small color="primary" dark @click="handleSave('close')" class="mr-1">{{ loc("Save and Go to List") }}</v-btn>
                    <v-btn small color="primary" dark @click="handleSave('lesson')" class="mr-1">{{ loc("Save and Go to Lesson") }}</v-btn>
                    <v-btn small color="primary" dark @click="handleSave('course')" class="mr-1">{{ loc("Save and Go to Course") }}</v-btn>
                    <v-btn small color="secondary" dark @click="handleCancel('close')" class="mr-1">{{ loc("Cancel") }}</v-btn>
                </v-row>
            </v-card-text>
        </v-card>     
        <v-row style="display:none">
            <pre>{{lessonRecord}}</pre>
        </v-row>   
    </div>
       
</template>

<script>
import { mapState } from 'vuex';
import SharedEditor from './Shared_Editor';
// import CodeEditor from './Shared_Editor_Code';
import AdminTitleBar from "./Admin_TitleBar";

export default {
    name: "AdminLessonsEdit",
    props: {
        editId: { type: String },
        lessonRecord: { type: Object },
        course: { type: Object }
    },
    components: { SharedEditor, AdminTitleBar },
    data() {
        return {
            record: this.lessonRecord,
            isnew: this.contentRecord && this.contentRecord._id === 'new',
            titleBarActions: [
                {
                    text: "Close",
                    key: "close"
                }
            ]              
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'adminstore', 'courses']),
        languages: function(){
            let list = (this.adminstore && this.adminstore.siteconfig && this.adminstore.siteconfig.languages) || {en:"English"};
            let out = [];
            for(let k in list){
                out.push({ value: k, text: list[k] });
            }
            return out;
        }        
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },  
        handleDescriptionChange: function(val){
            console.log(val);
            this.record.description = val;
        },        
        handleSave: function(val){
            if(val === "course") val = "/Course/"+this.record.course;
            this.$emit("actionhandler", "save", this.record, val);
        },
        handleCancel: function(val){
            this.$emit("actionhandler", 'close', val)
        },
        handleAction: function(action, params){
            this.$emit("actionhandler", action, params);
        },    }
    
}
</script>
